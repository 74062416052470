import * as React from "react";
import { graphql } from "gatsby";

import { Wrapper } from "../../components/globals/wrappers";
import styled from "styled-components";
import { useProjectTags } from "../../hooks/use-project-tags";
import { AnimateSharedLayout } from "framer-motion";
import { WorkGroupFilter } from "../../components/work-filter-bar";
import WorkTile from "../../components/work-tile";
import { stringToSlug } from "../../functions/util";

/**
 * Divider
 */

const WorkGridWrapper = styled(Wrapper)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.layout.outerWrapper};
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;

  @media ${({ theme }) => theme.device.tablet} {
    grid-auto-rows: auto;
    grid-template-columns: 1fr 1fr;
    margin-top: 4vw;
    margin-bottom: 4vw;
  }

  .work-link,
  a {
    display: inline-block;
    width: 100%;
  }
`;

function WorkGrid({ edges, showGroup }) {
  return (
    <WorkGridWrapper>
      <AnimateSharedLayout>
        {edges.map(({ node }, i) => {
          const tags = node.tags.map((tag, i) => stringToSlug(tag));

          if (tags.includes(showGroup) || showGroup === "all")
            return <WorkTile key={i} idx={i} node={node} />;
          else return null;
        })}
      </AnimateSharedLayout>
    </WorkGridWrapper>
  );
}

/**
 * page-template Component
 *
 * @param {Object} props
 * @param {Object} props.data
 */
export default function Work({
  data: {
    allPrismicProject: { edges, totalCount },
  },
  ...context
}) {
  const [showGroup, setShowGroup] = React.useState("all");
  const { group } = useProjectTags();
  const groupSet = [{ fieldValue: "all", totalCount: totalCount }, ...group];
  const {
    location: { search },
  } = context;

  React.useEffect(() => {
    if (search.includes("?tag=")) {
      const cleanLocation = search.replace("?tag=", "");
      setShowGroup(cleanLocation);
    }
  }, [search]);

  return (
    <>
      <WorkGroupFilter groupSet={groupSet} showGroup={showGroup} />
      <WorkGrid edges={edges} showGroup={showGroup} />
    </>
  );
}

/**
 * pageQuery
 */
export const pageQuery = graphql`
  query PrismicWorkpage {
    allPrismicProject(
      filter: { data: { page_visibility: { ne: false } } }
      sort: { fields: data___date, order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          uid
          type
          tags
          data {
            title
            marquee_image {
              fluid(maxWidth: 1800) {
                ...GatsbyPrismicImageFluid
                aspectRatio
              }
            }
          }
        }
      }
    }
  }
`;
