import React from "react";
import { Wrapper } from "./globals/wrappers";
import styled from "styled-components";
import Box from "./motion/motion-box";
import { hoverChange, stringToSlug, unslugify } from "../functions/util";
import Link from "./helpers/link";

const TagWrapper = styled(Wrapper)`
  margin-top: calc(6rem + 8vw);
  display: inline-flex;
  flex-wrap: wrap;
`;
const TagItem = styled(Link)`
  position: relative;
  display: inline-flex;
  margin-right: 3rem;
  opacity: 0.3;
  text-decoration: none;
  transition: transform ${({ theme }) => theme.animation.duration[300].css}
      ${({ theme }) => theme.animation.timingFunction.css},
    opacity ${({ theme }) => theme.animation.duration[100].css}
      ${({ theme }) => theme.animation.timingFunction.css} !important;

  &:hover {
    opacity: 0.5;
    transform: translateY(-0.1em);
  }

  &:active {
    opacity: 1;
    transform: translateY(0.1em);
  }

  &[data-active="true"] {
    opacity: 1;
  }

  .subscript {
    position: absolute;
    left: calc(100% + 0.5em);
    bottom: -0.1em;
  }
`;
export function WorkGroupFilter({ groupSet, showGroup }) {
  return (
    <TagWrapper>
      {groupSet.map(({ fieldValue, totalCount }, i) => {
        const active = stringToSlug(fieldValue) === showGroup;
        return (
          <Box key={i}>
            <TagItem
              data-active={active}
              to={`/work?tag=${stringToSlug(fieldValue)}`}
              onMouseEnter={() => !active && hoverChange("view-filter")}
              onMouseUp={() => !active && hoverChange(null)}
              onMouseLeave={() => !active && hoverChange(null)}
            >
              <p className="h6">{unslugify(fieldValue)}</p>
              <p className="subscript">{totalCount}</p>
            </TagItem>
          </Box>
        );
      })}
    </TagWrapper>
  );
}
